import React, {useState, useEffect} from "react";
import {Button, Checkbox, Container, Form, Grid, Icon, Input, Menu, Message} from "semantic-ui-react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import GeoOverridesExportModal from "./GeoOverridesExportModal";
import ContingentButton from "../../ContingentButton";

export default function GeoOverridesSelector(props) {
    const [filteredOverrides, setFilteredOverrides] = useState([]);
    const [filter, setFilter] = useState("");

    useEffect(function updateFilteredOverrides() {
        if (filter.length > 0) {
            const updatedFilteredOverrides = [];
            for (const override of props.overrides) {
                if ((override.hasOwnProperty("name") && override.name.toString().toLowerCase().includes(filter.toLowerCase())) || (override.hasOwnProperty("description") && override.description.toString().toLowerCase().includes(filter.toLowerCase()))) {
                    updatedFilteredOverrides.push(Object.assign({}, override));
                } else {
                    if (override.hasOwnProperty("cidr")) {
                        for (const cidr of override.cidr) {
                            if (cidr.includes(filter)) {
                                updatedFilteredOverrides.push(override);
                            }
                        }
                    }
                }
            }
            setFilteredOverrides(updatedFilteredOverrides);
        } else {
            setFilteredOverrides(props.overrides);
        }
    }, [filter, JSON.stringify(props.overrides)]);

    useEffect(() => {
        console.log(props.overrides);
    }, [JSON.stringify(props.overrides)])


    const onDragEnd = result => {
        if (!props.userCanEdit) {
            props.toast("Error", "You do not have permission to modify geo override settings.");
            return;
        }

        if (filter) {
            props.toast("Error", "You must clear the filter before you can modify the order of geo override settings.");
            return;
        }

        const {source, destination} = result;

        if (!destination || (source.index === destination.index)) {
            return;
        }

        const updatedOverrides = props.overrides.slice();

        const [removed] = updatedOverrides.splice(source.index, 1);
        updatedOverrides.splice(destination.index, 0, removed);
        props.setOverrides(updatedOverrides);
    };

    return (
        <Grid>
            <Grid.Column>
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            <label>&nbsp;</label>
                            <GeoOverridesExportModal
                                open={props.openExportModal}
                                setOpen={props.setOpenExportModal}
                                rulesSelected={props.rulesSelected}
                                trigger={
                                    <ContingentButton
                                        icon
                                        fluid
                                        onClick={() => props.setOpenExportModal(true)}
                                        disabled={props.rulesSelected.length < 1}
                                        service={props.service}
                                        module={props.module}
                                        scope="all"
                                        user={props.user}
                                        allPermissions={props.permissions}
                                    >
                                        <Icon name="download"/> Export
                                    </ContingentButton>
                                }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>&nbsp;</label>
                            <ContingentButton
                                icon
                                secondary
                                onClick={() => props.setNewClicked(true)}
                                fluid
                                service={props.service}
                                module={props.module}
                                scope="all"
                                user={props.user}
                                allPermissions={props.permissions}
                            ><Icon name="plus"/> Create</ContingentButton>
                        </Form.Field>
                        <Form.Field>
                            <label>&nbsp;</label>
                            <Button icon onClick={() => props.refreshOverrides()} fluid><Icon name="refresh"/> Refresh</Button>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                        <Form.Field
                            label="&nbsp;"
                            control={Input}
                            onChange={(event, {value}) => setFilter(value)}
                            value={filter}
                            fluid
                            icon="search"
                            width={11}
                            placeholder="Filter Settings"
                        />
                    </Form.Group>
                </Form>
                <Container style={{maxHeight: "72.5vh", overflowY: "auto"}}>
                    {
                        props.loading ?
                            <Message icon color="yellow">
                                <Icon name="spinner" loading />
                                <Message.Content>Loading Override Settings...</Message.Content>
                            </Message> :
                            <Menu vertical fluid>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    {
                                        filteredOverrides.length > 0 ?
                                            <Droppable droppableId={"droppable"}>
                                                {
                                                    provided =>
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {
                                                                filteredOverrides.map((setting, index) =>
                                                                    <Draggable key={`setting-${index}`} draggableId={`setting-${index}`} index={index}>
                                                                        {
                                                                            provided =>
                                                                                <div
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                >
                                                                                    <Menu.Item
                                                                                        innerRef={provided.innerRef}
                                                                                        {...provided.droppableProps}
                                                                                        active={JSON.stringify(props.selectedOverride.index) === JSON.stringify(setting.index)}
                                                                                        onClick={
                                                                                            () => {
                                                                                                props.setSelectedKey(setting.ruleId);
                                                                                                props.setNewClicked(false);
                                                                                                props.setSelectedOverride(setting);
                                                                                            }
                                                                                        }
                                                                                        fluid
                                                                                    >
                                                                                        <Grid>
                                                                                            <Grid.Row verticalAlign="middle">
                                                                                                <Grid.Column width={2}>
                                                                                                    <Checkbox
                                                                                                        checked={Object.keys(props.rulesSelected).includes(setting.ruleId)}
                                                                                                        onClick={
                                                                                                            () => {
                                                                                                                let updatedRulesSelected = {};
                                                                                                                const isRuleCurrentlySelected = Object.keys(props.rulesSelected).includes(setting.ruleId);
                                                                                                                for (const selectedRule of Object.keys(props.rulesSelected)) {
                                                                                                                    if (selectedRule !== setting.ruleId || !isRuleCurrentlySelected) {
                                                                                                                        updatedRulesSelected[selectedRule] = Object.assign({}, props.rulesSelected[selectedRule]);
                                                                                                                    }
                                                                                                                }

                                                                                                                if (!isRuleCurrentlySelected) {
                                                                                                                    updatedRulesSelected[setting.ruleId] = Object.assign({}, setting);
                                                                                                                }
                                                                                                                props.setRulesSelected(updatedRulesSelected);
                                                                                                            }
                                                                                                        }
                                                                                                    />
                                                                                                </Grid.Column>
                                                                                                <Grid.Column width={14}>
                                                                                                    <h5>{setting.ruleId}</h5>
                                                                                                    <>{setting.description}</>
                                                                                                    {provided.placeholder}
                                                                                                </Grid.Column>
                                                                                            </Grid.Row>
                                                                                        </Grid>
                                                                                    </Menu.Item>
                                                                                </div>
                                                                        }
                                                                    </Draggable>
                                                                )
                                                            }
                                                            {provided.placeholder}
                                                        </div>
                                                }
                                            </Droppable> :
                                            <Message icon color="yellow">
                                                <Icon name="warning" />
                                                <Message.Content>No override settings match your current search filter.</Message.Content>
                                            </Message>
                                    }
                                </DragDropContext>
                            </Menu>
                    }
                </Container>
            </Grid.Column>
        </Grid>
    );
};
