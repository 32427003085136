import React, {useEffect, useState} from "react";
import {Container, Grid, Icon, Step} from "semantic-ui-react";
import GeoOverridesImportStep from "./GeoOverridesImportStep";
import GeoOverridesArrangementStep from "./GeoOverridesArrangementStep";

export default function GeoOverridesImportTab(props) {
    const [rulesToImport, setRulesToImport] = useState({});
    const [allRules, setAllRules] = useState({});
    const [ruleIds, setRuleIds] = useState([]);
    const [importError, setImportError] = useState("");
    const [saving, setSaving] = useState(false);
    const [isImportStep, setIsImportStep] = useState(true);
    const [saveErrorMessage, setSaveErrorMessage] = useState("");
    const [saveSuccessMessage, setSaveSuccessMessage] = useState("");

    useEffect(function updateAllRuleIds() {
        if (Array.isArray(props.overrides) && props.overrides.length > 0) {
            const updatedAllRules = [];
            for (const override of props.overrides) {
                updatedAllRules[override.ruleId] = override;
            }
            setAllRules(updatedAllRules);
        } else {
            setAllRules([]);
        }
    }, [JSON.stringify(props.overrides)]);

    const importRules = files => {
        setImportError("");
        const file = files.item(0);
        file.text().then(text => {
            const json = JSON.parse(text);
            if (!Array.isArray(json)) {
                setImportError("The file imported is not properly formatted, or was modified after export.");
                return;
            }

            const updatedRules = {};
            const updatedRuleIds = [];

            for (const rule of json) {
                const ruleId = rule.ruleId;
                if (ruleId) {
                    updatedRuleIds.push(ruleId);
                    const ruleExists = allRules.hasOwnProperty(ruleId);
                    updatedRules[ruleId] = {
                        import: !ruleExists,
                        status: ruleExists ? "error" : "ready",
                        details: ruleExists ? "Rule Already Exists" : "Ready to Import",
                        rule
                    };
                }
            }
            setRuleIds(updatedRuleIds);
            setRulesToImport(updatedRules);
        }).catch(error => {
            console.error(error);
            setImportError("There was an error importing your file.");
        });
    };

    const arrangeOverridesClicked = async () => {
        setIsImportStep(false);
    };

    const goBackClicked = () => {
        setIsImportStep(true);
    }

    const saveRearrangedOverrides = updatedOverrides => {
        console.log("GeoOverridesImportTab.saveRearrangedOverrides updated overrides: ", updatedOverrides);
        setSaveSuccessMessage("");
        setSaveErrorMessage("");

        for (const override of updatedOverrides) {
            delete override.index;
        }

        console.log("GeoOverridesImportTab.onClickSave: overrides that will be saved: ", updatedOverrides);

        setSaving(true);
        props.GeoDataProvider.setMetadata("overrides", updatedOverrides).then(response => {
            console.log("GeoOverridesImportTab.onClickSave response: ", response);
        }).then(() => {
            setSaveSuccessMessage("This override setting has been successfully saved.");
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage("There has been an error saving the override setting.");
        }).finally(() => {
            setSaving(false);
        });
    };

    return (
        <Grid className="masterContainer">
            <Step.Group attached="top">
                <Step active={isImportStep} completed={!isImportStep}>
                    <Icon name="upload" />
                    <Step.Content>
                        <Step.Title>Import Overrides</Step.Title>
                    </Step.Content>
                </Step>
                <Step active={!isImportStep}>
                    <Icon name="random" />
                    <Step.Content>
                        <Step.Title>Arrange Overrides</Step.Title>
                    </Step.Content>
                </Step>
            </Step.Group>
            <Container>
                {
                    isImportStep ?
                        <GeoOverridesImportStep
                            importRules={importRules}
                            rulesToImport={rulesToImport}
                            setRulesToImport={setRulesToImport}
                            arrangeOverridesClicked={arrangeOverridesClicked}
                            ruleIds={ruleIds}
                            saving={saving}
                            importError={importError}
                        /> :
                        <GeoOverridesArrangementStep
                            rulesToImport={rulesToImport}
                            allRules={allRules}
                            saving={saving}
                            saveRearrangedOverrides={saveRearrangedOverrides}
                            saveErrorMessage={saveErrorMessage}
                            saveSuccessMessage={saveSuccessMessage}
                            goBackClicked={goBackClicked}
                        />
                }
            </Container>
        </Grid>
    );
};
