import React, {useState, useEffect} from "react";
import {Button, Container, Grid, Icon, Input, Menu, Message, Modal, Table} from "semantic-ui-react";
import FileSaver from "file-saver";

export default function GeoOverridesExportModal(props) {
    const [ruleIds, setRuleIds] = useState([]);
    const [ruleValues, setRuleValues] = useState({});
    const [selectedId, setSelectedId] = useState("");
    const [selectedRule, setSelectedRule] = useState({});
    const [exportFilename, setExportFilename] = useState("export.json");

    useEffect(function updateProfileIds() {
        setRuleIds(Object.keys(props.rulesSelected));
        setRuleValues(props.rulesSelected);
    }, [props.rulesSelected]);

    useEffect(function updateSelectedRule() {
        if (ruleValues.hasOwnProperty(selectedId)) {
            setSelectedRule(ruleValues[selectedId]);
        } else {
            setSelectedRule({});
        }
    }, [selectedId]);

    useEffect(function clearOnClose() {
        if (!props.open) {
            setSelectedRule({});
            setSelectedId("");
            setExportFilename("export.json");
        }
    }, [props.open]);

    const exportJSON = filename => {
        if (!filename) {
            filename = "export.json";
        }
        const records = [];
        for (const record of Object.values(props.rulesSelected)) {
            delete record.index;
            records.push(record);
        }

        let file = new File([JSON.stringify(records)], filename, {type: "application/json;charset=utf-8"});
        FileSaver.saveAs(file);
        props.setOpen(false);
    }


    return (
        <Modal
            size="large"
            open={props.open}
            onClose={() => props.setOpen(false)}
            trigger={props.trigger}
            style={{position: "relative"}}
        >
            <Modal.Header>
                Geo Rule Export
            </Modal.Header>
            <Modal.Content scrolling style={{height: "50vh"}}>
                <Modal.Description>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <Input
                                    label="Export Filename"
                                    value={exportFilename}
                                    onChange={(event, {value}) => setExportFilename(value)}
                                    fluid
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Column width={3}>
                            <Menu vertical fluid secondary>
                                {
                                    ruleIds.map(id =>
                                        <Menu.Item
                                            key={`rule-export-${id}`}
                                            active={id === selectedId}
                                            onClick={() => setSelectedId(id)}
                                        >{id}</Menu.Item>
                                    )
                                }
                            </Menu>
                        </Grid.Column>
                        <Grid.Column width={13}>
                            {
                                !selectedId ?
                                    <Message icon color="yellow">
                                        <Icon name="info"/>
                                        If you wish to inspect the rules to export, you can click on the IDs on the left. You can also just click the export button.
                                    </Message> :
                                    Object.keys(selectedRule).length > 0 ?
                                        <Container>
                                            <Table fixed celled striped>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;Rule ID</Table.HeaderCell>
                                                        <Table.Cell>{selectedRule.ruleId}</Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;Description</Table.HeaderCell>
                                                        <Table.Cell>{selectedRule.description}</Table.Cell>
                                                    </Table.Row>
                                                    {
                                                        selectedRule.hasOwnProperty("cidr") ?
                                                            selectedRule.cidr.map((cidr, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "CIDRs" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{cidr}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("network") ?
                                                            selectedRule.network.map((network, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "Networks" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{network}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("product") ?
                                                            selectedRule.product.map((product, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "Products" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{product}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("platform") ?
                                                            selectedRule.platform.map((platform, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "Platforms" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{platform}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("proxyid") ?
                                                            selectedRule.proxyid.map((proxyId, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "Proxy IDs" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{proxyId}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("proxytype") ?
                                                            selectedRule.proxytype.map((proxyType, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "Proxy Types" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{proxyType}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("version") ?
                                                            selectedRule.version.map((version, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{index === 0 ? "Versions" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{version}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) : ""

                                                    }
                                                </Table.Body>
                                            </Table>
                                            {
                                                selectedRule.hasOwnProperty("attrs") ?
                                                    <Table fixed celled striped>
                                                        <Table.Header>
                                                            <Table.Row>
                                                                <Table.HeaderCell colSpan="2">Rule Attributes</Table.HeaderCell>
                                                            </Table.Row>
                                                        </Table.Header>
                                                        <Table.Body>
                                                            {
                                                                Object.keys(selectedRule.attrs).map(key =>
                                                                    <Table.Row>
                                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{key}</Table.HeaderCell>
                                                                        <Table.Cell><pre>{JSON.stringify(selectedRule.attrs[key], null, 4)}</pre></Table.Cell>
                                                                    </Table.Row>
                                                                )
                                                            }
                                                        </Table.Body>
                                                    </Table> : ""
                                            }
                                        </Container> : ""
                            }
                        </Grid.Column>
                    </Grid>
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.setOpen(false)} secondary>Cancel</Button>
                <Button onClick={() => exportJSON(exportFilename)} primary>Export</Button>
            </Modal.Actions>
        </Modal>
    );
};
