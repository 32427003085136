import React, {useState, useEffect} from "react";
import {Button, Form, Grid, Icon, Menu, Message} from "semantic-ui-react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ContingentButton from "../../ContingentButton";

export default function GeoOverridesArrangementStep(props) {
    const [importedOverridesRemaining, setImportedOverridesRemaining] = useState([]);
    const [overridesToSave, setOverridesToSave] = useState([]);

    useEffect(function initializeOverridesToSave() {
        setOverridesToSave(Object.values(props.allRules));
    }, [JSON.stringify(props.allRules)]);

    useEffect(function initializeImportedOverrides() {
        const updatedOverridesRemaining = [];
        for (const override of Object.values(props.rulesToImport)) {
            if (override.import) {
                updatedOverridesRemaining.push(Object.assign({}, override.rule));
            }
        }
        setImportedOverridesRemaining(updatedOverridesRemaining);
    }, [JSON.stringify(props.rulesToImport)]);

    const onDragEnd = result => {
        console.log("onDragEnd result: ", result);
        const {source, destination} = result;

        if (!destination || (source.index === destination.index)) {
            return;
        }

        if (source.droppableId === destination.droppableId && source.droppableId === "droppable-to-save") {
            const updatedOverridesToSave = overridesToSave.slice();
            const [removed] = updatedOverridesToSave.splice(source.index, 1);
            updatedOverridesToSave.splice(destination.index, 0, removed);
            setOverridesToSave(updatedOverridesToSave);
        } else if (source.droppableId !== destination.droppableId && destination.droppableId === "droppable-to-save") {
            const updatedImportedOverridesRemaining = importedOverridesRemaining.slice();
            const updatedOverridesToSave = overridesToSave.slice();

            const [removed] = updatedImportedOverridesRemaining.splice(source.index, 1);
            updatedOverridesToSave.splice(destination.index, 0, removed);

            setImportedOverridesRemaining(updatedImportedOverridesRemaining);
            setOverridesToSave(updatedOverridesToSave);
        }
    };

    return (
        <Grid>
            <Grid.Row />
            <Grid.Row>
                <Grid.Column width={16}>
                    <Form>
                        <Form.Group widths="equal">
                            <Form.Field>
                                <ContingentButton
                                    primary
                                    floated="right"
                                    onClick={() => props.saveRearrangedOverrides(overridesToSave)}
                                    service={props.service}
                                    module={props.module}
                                    scope="all"
                                    user={props.user}
                                    allPermissions={props.permissions}
                                >Save</ContingentButton>
                                <Button secondary floated="right" onClick={() => props.goBackClicked()}>Go Back</Button>
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                {
                    props.saving ?
                        <Message icon color="blue">
                            <Icon name="spinner" loading />
                            <Message.Content>Saving...</Message.Content>
                        </Message> :
                    props.saveSuccessMessage ?
                        <Message icon color="green">
                            <Icon name="check" />
                            <Message.Content>The new overrides have been successfully updated.</Message.Content>
                        </Message> :
                    props.saveErrorMessage ?
                        <Message icon color="red">
                            <Icon name="x" />
                            <Message.Content>There was an error saving the updated overrides.</Message.Content>
                        </Message> : ""
                }
            </Grid.Row>
            <Grid.Row>
                <Menu vertical fluid>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={8}>
                                    <Menu vertical fluid>
                                        <Droppable droppableId="droppable-remaining">
                                            {
                                                (provided, snapshot) => {
                                                    console.log("provided: ", provided, "snapshot: ", snapshot);
                                                    return (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {
                                                                importedOverridesRemaining.map((override, index) =>
                                                                    <Draggable key={`new-override-setting-${index}`} draggableId={`new-override-${index}`} index={index}>
                                                                        {
                                                                            provided => {
                                                                                console.log("provided: ", provided, "override: ", override);
                                                                                return (
                                                                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{position: "static"}}>
                                                                                        <Menu.Item innerRef={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                            <Grid>
                                                                                                <Grid.Row verticalAlign="middle">
                                                                                                    <Grid.Column width={16}>
                                                                                                        <h5>{override.ruleId}</h5>
                                                                                                        <>{override.description}</>
                                                                                                        {provided.placeholder}
                                                                                                    </Grid.Column>
                                                                                                </Grid.Row>
                                                                                            </Grid>
                                                                                        </Menu.Item>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                        }
                                                                    </Draggable>
                                                                )
                                                            }
                                                            {provided.placeholder}
                                                        </div>
                                                    );
                                                }
                                            }
                                        </Droppable>
                                    </Menu>
                                </Grid.Column>
                                <Grid.Column width={8}>
                                    <Droppable droppableId={"droppable-to-save"}>
                                        {
                                            provided =>
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {
                                                        overridesToSave.map(
                                                            (setting, index) =>
                                                                <Draggable key={`current-override-setting-${index}`} draggableId={`current-override-${index}`} index={index}>
                                                                    {
                                                                        provided =>
                                                                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                <Menu.Item innerRef={provided.innerRef} {...provided.droppableProps} fluid>
                                                                                    <Grid>
                                                                                        <Grid.Row verticalAlign="middle">
                                                                                            <Grid.Column width={16}>
                                                                                                <h5>{setting.ruleId}</h5>
                                                                                                <>{setting.description}</>
                                                                                                {provided.placeholder}
                                                                                            </Grid.Column>
                                                                                        </Grid.Row>
                                                                                    </Grid>
                                                                                </Menu.Item>
                                                                            </div>
                                                                    }
                                                                </Draggable>
                                                        )
                                                    }
                                                    {provided.placeholder}
                                                </div>
                                        }
                                    </Droppable>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </DragDropContext>
                </Menu>
            </Grid.Row>
        </Grid>
    );
};
