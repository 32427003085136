import React, {useState, useEffect} from "react";
import {Grid, Tab} from "semantic-ui-react";
import GeoDataProvider from "../../../Services/GeoDataProvider";
import GeoOverridesImportTab from "./GeoOverridesImportTab";
import GeoOverridesArrangementTab from "./GeoOverridesArrangementTab";
import SecureTabPane from "../../SecureTabPane";

GeoDataProvider.init(process.env.GEO_URL);

export default function GeoOverridesMasterDetailView(props) {
    const [overrides, setOverrides] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState("");
    const [saveErrorMessage, setSaveErrorMessage] = useState("");
    const [saveSuccessMessage, setSaveSuccessMessage] = useState("");

    useEffect(function initializeOverrides() {
        refreshOverrides();
    }, []);

    const refreshOverrides = () => {
        setLoading(true);
        setLoadingErrorMessage("");
        setSaveSuccessMessage("");
        setSaveErrorMessage("");
        GeoDataProvider.getMetadata().then(response => {
            console.log("getMetadata response", response)
            if (response.error) {
                console.error(response.error);
                setLoadingErrorMessage("There was an error loading the metadata.");
                return [];
            }
            if (!response.metadata || !Array.isArray(response.metadata.overrides)) {
                setLoadingErrorMessage("There was an error loading the metadata.");
                return [];
            }

            return response.metadata.overrides.slice();
        }).then(enrichedOverrides => {
            const updatedRuleIds = new Set();
            for (let i = 0; i < enrichedOverrides.length; i++) {
                enrichedOverrides[i].index = i;
                updatedRuleIds.add(enrichedOverrides[i].ruleId);
            }
            setOverrides(enrichedOverrides.slice());
        }).catch(error => {
            console.error(error);
            setLoadingErrorMessage("There was an error loading the metadata.");
        }).finally(() => {
            setLoading(false);
        });
    };

    return (
        <Grid className="masterContainer">
            <Tab
                panes={[
                    {
                        menuItem: "Arrange Existing Overrides",
                        render: () =>
                            <SecureTabPane
                                authenticated={props.authenticated}
                                checkIfAuthorized={props.checkIfAuthorized}
                                service={props.service}
                                permissions={props.permissions}
                                module={props.module}
                                userPermissions={props.userPermissions}
                                user={props.user}
                                loadingPermissions={props.loadingPermissions}
                                component={
                                    <GeoOverridesArrangementTab
                                        overrides={overrides}
                                        setOverrides={setOverrides}
                                        loading={loading}
                                        refreshOverrides={refreshOverrides}
                                        loadingErrorMessage={loadingErrorMessage}
                                        saveErrorMessage={saveErrorMessage}
                                        saveSuccessMessage={saveSuccessMessage}
                                        service={props.service}
                                        permissions={props.permissions}
                                        module={props.module}
                                        user={props.user}
                                        loadingPermissions={props.loadingPermissions}
                                    />
                                }
                            />
                    },
                    {
                        menuItem: "Import Overrides",
                        render: () =>
                            <SecureTabPane
                                authenticated={props.authenticated}
                                checkIfAuthorized={props.checkIfAuthorized}
                                service={props.service}
                                permissions={props.permissions}
                                module={props.module}
                                userPermissions={props.userPermissions}
                                user={props.user}
                                loadingPermissions={props.loadingPermissions}
                                role="editor"
                                component={
                                    <GeoOverridesImportTab
                                        GeoDataProvider={GeoDataProvider}
                                        overrides={overrides}
                                        service={props.service}
                                        permissions={props.permissions}
                                        module={props.module}
                                        user={props.user}
                                        loadingPermissions={props.loadingPermissions}
                                    />
                                }
                            />
                    }
                ]}
            />
        </Grid>
    );
};
