import React, {useState, useEffect} from "react";
import {Button, Checkbox, Container, Grid, Icon, Menu, Message, Popup, Table} from "semantic-ui-react";
import ReactFileReader from "react-file-reader";

export default function GeoOverridesImportStep(props) {
    const [selectedId, setSelectedId] = useState("");
    const [selectedRule, setSelectedRule] = useState({});

    useEffect(function onRuleSelect() {
        if (selectedId && props.rulesToImport.hasOwnProperty(selectedId)) {
            setSelectedRule(props.rulesToImport[selectedId].rule);
        } else {
            setSelectedRule({});
        }
    }, [selectedId]);


    return (
        <Grid className="masterContainer">
            <Grid.Row>
                <Grid.Column width={8} textAlign="left">
                    <ReactFileReader handleFiles={props.importRules} fileTypes={[".json"]} multipleFiles={false} base64={false}>
                        <Button secondary>Select File to Import</Button>
                    </ReactFileReader>
                </Grid.Column>
                <Grid.Column width={8} textAlign="right">
                    {
                        Object.keys(props.rulesToImport).length > 0 ?
                            <Button primary onClick={() => props.arrangeOverridesClicked()}>Arrange Selected Overrides</Button> : ""
                    }
                </Grid.Column>
            </Grid.Row>
            {
                Object.keys(props.rulesToImport).length > 0 ?
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Container fluid>
                                <Menu vertical fluid secondary>
                                    {
                                        Object.keys(props.rulesToImport).map(ruleId => {
                                            const override = props.rulesToImport[ruleId];
                                            if (override) {
                                                const status = override.status;
                                                let icon =
                                                    status === "error" ? "x" :
                                                        status === "success"? "check" :
                                                            status === "invalid" ? "ban" :
                                                                status === "saving" ? "spinner" :
                                                                    status === "warning" ? "exclamation" : "plus";
                                                let statusColor =
                                                    status === "error" || status === "invalid" ? "red" :
                                                        status === "saving" ? "blue" :
                                                            status === "warning" ? "yellow" :
                                                                status === "success" ? "green" : "black";
                                                return (
                                                    <Menu.Item
                                                        key={`override-import-${ruleId}`}
                                                        active={ruleId === selectedId}
                                                        onClick={() => setSelectedId(ruleId)}
                                                    >
                                                        <Checkbox
                                                            checked={props.rulesToImport[ruleId].import}
                                                            onClick={
                                                                (event, {checked}) => {
                                                                    if (props.rulesToImport[ruleId].status !== "error") {
                                                                        const updatedImportedRules = Object.assign({}, props.rulesToImport);
                                                                        updatedImportedRules[ruleId] = Object.assign({}, props.rulesToImport[ruleId], {import: checked});
                                                                        props.setRulesToImport(updatedImportedRules);
                                                                    }
                                                                }
                                                            }
                                                            disabled={props.rulesToImport[ruleId].status === "error"}
                                                        />
                                                        &nbsp;{ruleId}
                                                        <Popup
                                                            hoverable
                                                            content={props.rulesToImport[ruleId].details}
                                                            trigger={<Icon name={icon} size="large" color={statusColor} loading={props.saving && status === "saving"} />}
                                                        />
                                                    </Menu.Item>
                                                );
                                            } else {
                                                console.log(`override with id ${ruleId} was not found or invalid`, props.rulesToImport[ruleId], props.rulesToImport);
                                            }
                                        })
                                    }
                                </Menu>
                            </Container>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Container fluid>
                                {
                                    selectedId.length === 0 ?
                                        <Message icon color="yellow">
                                            <Icon name="info" />
                                            <Message.Content>
                                                Select a rule to see its details and import status.
                                            </Message.Content>
                                        </Message> :
                                        <Container>
                                            <Table fixed celled striped>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;Rule ID</Table.HeaderCell>
                                                        <Table.Cell>{selectedId}</Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;Description</Table.HeaderCell>
                                                        <Table.Cell>{selectedRule.description}</Table.Cell>
                                                    </Table.Row>
                                                    {
                                                        selectedRule.hasOwnProperty("cidr") ?
                                                            selectedRule.cidr.map((cidr, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "CIDRs" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{cidr}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;CIDRs</Table.HeaderCell>
                                                                <Table.Cell>This rule specifies no CIDRs.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("network") ?
                                                            selectedRule.network.map((network, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "Networks" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{network}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Networks</Table.HeaderCell>
                                                                <Table.Cell>This rule specifies no networks.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("product") ?
                                                            selectedRule.product.map((product, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "Products" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{product}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Products</Table.HeaderCell>
                                                                <Table.Cell>This rule specifies no products.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("platform") ?
                                                            selectedRule.platform.map((platform, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "Platforms" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{platform}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Platforms</Table.HeaderCell>
                                                                <Table.Cell>This rule specifies no platforms.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("proxyid") ?
                                                            selectedRule.proxyid.map((proxyId, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "Proxy IDs" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{proxyId}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Proxy IDs</Table.HeaderCell>
                                                                <Table.Cell>This rule specifies no proxy IDs.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("proxytype") ?
                                                            selectedRule.proxytype.map((proxyType, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "Proxy Types" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{proxyType}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Proxy Types</Table.HeaderCell>
                                                                <Table.Cell>This rule specifies no proxy types.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                    {
                                                        selectedRule.hasOwnProperty("version") ?
                                                            selectedRule.version.map((version, index) => {
                                                                return (
                                                                    <Table.Row>
                                                                        <Table.HeaderCell>&nbsp;&nbsp;&nbsp;{index === 0 ? "Versions" : ""}</Table.HeaderCell>
                                                                        <Table.Cell>{version}</Table.Cell>
                                                                    </Table.Row>
                                                                );
                                                            }) :
                                                            <Table.Row>
                                                                <Table.HeaderCell>&nbsp;&nbsp;&nbsp;Versions</Table.HeaderCell>
                                                                <Table.Cell>This rule specifies no versions.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                </Table.Body>
                                            </Table>
                                            <Table celled striped>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell colSpan="2">Rule Attrs</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>
                                                <Table.Body>
                                                    {
                                                        selectedRule.hasOwnProperty("attrs") ? Object.keys(selectedRule.attrs).map(key =>
                                                                <Table.Row key={`${selectedId}-${key}`}>
                                                                    <Table.HeaderCell width={3}>&nbsp;&nbsp;&nbsp;{key}</Table.HeaderCell>
                                                                    <Table.Cell><pre>{JSON.stringify(selectedRule.attrs[key], null, 4)}</pre></Table.Cell>
                                                                </Table.Row>
                                                            ) :
                                                            <Table.Row>
                                                                <Table.Cell colSpan="2">This rule specifies no extra attributes.</Table.Cell>
                                                            </Table.Row>
                                                    }
                                                </Table.Body>
                                            </Table>
                                        </Container>
                                }
                            </Container>

                        </Grid.Column>
                    </Grid.Row> : ""
            }
        </Grid>
    );
}
