import React, {Component, useEffect, useState} from "react";
import {Button, Divider, Form, Grid, Icon, Input, Label, Message, Popup, Segment, Select, TextArea} from "semantic-ui-react";
import AraDataProvider from "../../../Services/ARADataProvider";
import JSONEditor from "jsoneditor";
import _ from "lodash";
import ContingentButton from "../../ContingentButton";

AraDataProvider.init({baseURL: process.env.PROD_ARA});

const VERSION_OPTIONS = [
    {key: "v1", text: "v1", value: "v1"},
    {key: "v2", text: "v2", value: "v2"}
];

export default function GeoOverridesDetailView(props) {
    const [ruleId, setRuleId] = useState("");
    const [description, setDescription] = useState("");
    const [allNetworks, setAllNetworks] = useState([]);
    const [allProducts, setAllProducts] = useState([]);
    const [allPlatforms, setAllPlatforms] = useState([]);
    const [remainingNetworkOptions, setRemainingNetworkOptions] = useState([]);
    const [remainingProductOptions, setRemainingProductOptions] = useState([]);
    const [remainingPlatformOptions, setRemainingPlatformOptions] = useState([]);
    const [networksToAdd, setNetworksToAdd] = useState([]);
    const [productsToAdd, setProductsToAdd] = useState([]);
    const [platformsToAdd, setPlatformsToAdd] = useState([]);
    const [cidrToAdd, setCidrToAdd] = useState("");
    const [proxyIdToAdd, setProxyIdToAdd] = useState("");
    const [proxyTypeToAdd, setProxyTypeToAdd] = useState("");
    const [versionsToAdd, setVersionsToAdd] = useState([]);
    const [networks, setNetworks] = useState([]);
    const [products, setProducts] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [cidrs, setCidrs] = useState([]);
    const [proxyIds, setProxyIds] = useState([]);
    const [proxyTypes, setProxyTypes] = useState([]);
    const [versions, setVersions] = useState([]);
    const [attrs, setAttrs] = useState({});
    const [index, setIndex] = useState(-1);

    useEffect(function initARA() {
        AraDataProvider.getAll().then(response => {
            const networks = new Set();
            const platforms = new Set();
            const products = new Set();

            for (const appId of response) {
                if (appId.network) {
                    networks.add(appId.network);
                }
                if (appId.platform) {
                    platforms.add(appId.platform);
                }
                if (appId.product) {
                    products.add(appId.product);
                }
            }

            setAllNetworks(Array.from(networks).sort());
            setAllProducts(Array.from(products).sort());
            setAllPlatforms(Array.from(platforms).sort());

        }).catch(error => {
            console.error(error);
            props.setLoadingErrorMessage("There was an error retrieving networks, products, and platforms from ARA.");
        });
    }, []);

    useEffect(function updateRemainingNetworks() {
        console.log("networks", networks);
        const updatedNetworkOptions = [];
        for (const network of allNetworks) {
            if (!networks.includes(network)) {
                updatedNetworkOptions.push({key: network, text: network, value: network});
            }
        }
        setRemainingNetworkOptions(updatedNetworkOptions);
    }, [JSON.stringify(allNetworks), JSON.stringify(networks)]);

    useEffect(function updateRemainingPlatforms() {
        const updatedPlatformOptions = [];
        for (const platform of allPlatforms) {
            if (!platforms.includes(platform)) {
                updatedPlatformOptions.push({key: platform, text: platform, value: platform});
            }
        }
        setRemainingPlatformOptions(updatedPlatformOptions);
    }, [JSON.stringify(allPlatforms), JSON.stringify(platforms)]);

    useEffect(function updateRemainingProducts() {
        const updatedProductOptions = [];
        for (const product of allProducts) {
            if (!products.includes(product)) {
                updatedProductOptions.push({key: product, text: product, value: product});
            }
        }
        setRemainingProductOptions(updatedProductOptions)
    }, [JSON.stringify(allProducts), JSON.stringify(products)]);

    useEffect(function onSelectedOverrideUpdate() {
        setRuleId(props.selectedOverride.ruleId || "");
        setDescription(props.selectedOverride.description || "");
        setNetworks(props.selectedOverride.network || []);
        setPlatforms(props.selectedOverride.platform || []);
        setProducts(props.selectedOverride.product || []);
        setProxyIds(props.selectedOverride.proxyid || []);
        setProxyTypes(props.selectedOverride.proxytype || []);
        setVersions(props.selectedOverride.version || []);
        setCidrs(props.selectedOverride.cidr || []);
        setAttrs(Object.assign({}, props.selectedOverride.attrs));
        setNetworksToAdd([]);
        setProductsToAdd([]);
        setPlatformsToAdd([]);
        setCidrToAdd("");
        setProxyIdToAdd("");
        setProxyTypeToAdd("");
        setVersionsToAdd([]);
        setIndex(props.selectedOverride.index);
    }, [JSON.stringify(props.selectedOverride)]);

    useEffect(function checkUnsavedChanges() {
        const ruleIdUpdated = ruleId !== (props.selectedOverride.ruleId || "");
        const descriptionUpdated = description !== (props.selectedOverride.description || "");
        const networksUpdated = _.difference(networks, props.selectedOverride.network).length > 0 || _.difference(props.selectedOverride.network, networks).length > 0;
        const productsUpdated = _.difference(products, props.selectedOverride.product).length > 0 || _.difference(props.selectedOverride.product, products).length > 0;
        const platformsUpdated = _.difference(platforms, props.selectedOverride.platform).length > 0 || _.difference(props.selectedOverride.platform, platforms).length > 0;
        const proxyIdsUpdated = _.difference(proxyIds, props.selectedOverride.proxyid).length > 0 || _.difference(props.selectedOverride.proxyid, proxyIds).length > 0;
        const proxyTypesUpdated = _.difference(proxyTypes, props.selectedOverride.proxytype).length > 0 || _.difference(props.selectedOverride.proxytype, proxyTypes).length > 0;
        const versionsUpdated = _.difference(versions, props.selectedOverride.version).length > 0 || _.difference(props.selectedOverride.version, versions).length > 0;
        const cidrsUpdated = _.difference(cidrs, props.selectedOverride.cidr).length > 0 || _.difference(props.selectedOverride.cidr, cidrs).length > 0;
        let attrsUpdated = false;
        let attrsProps = Object.assign({}, props.selectedOverride.attrs);

        for (const attr of Object.keys(attrs)) {
            if (attrsUpdated) break;
            attrsUpdated ||= !attrsProps.hasOwnProperty(attr) || attrsProps[attr] !== attrs[attr];
        }

        for (const attr of Object.keys(attrsProps)) {
            if (attrsUpdated) break;
            attrsUpdated ||= !attrs.hasOwnProperty(attr) || attrs[attr] !== attrsProps[attr];
        }

        props.setIsSelectedOverrideUpdated(ruleIdUpdated || descriptionUpdated || networksUpdated || productsUpdated ||
            platformsUpdated || proxyIdsUpdated || proxyTypesUpdated || versionsUpdated || cidrsUpdated || attrsUpdated);
    }, [props.selectedOverride, ruleId, description, JSON.stringify(networks), JSON.stringify(products), JSON.stringify(platforms),
        JSON.stringify(proxyIds), JSON.stringify(proxyTypes), JSON.stringify(cidrs), JSON.stringify(versions), JSON.stringify(attrs)]);

    const onClickSave = () => {
        const updatedOverride = {
            ruleId,
            ...description && {description},
            ...Array.isArray(cidrs) && cidrs.length > 0 && {cidr: cidrs},
            ...Array.isArray(networks) && networks.length > 0 && {network: networks},
            ...Array.isArray(products) && products.length > 0 && {product: products},
            ...Array.isArray(platforms) && platforms.length > 0 && {platform: platforms},
            ...Array.isArray(proxyIds) && proxyIds.length > 0 && {proxyid: proxyIds},
            ...Array.isArray(proxyTypes) && proxyTypes.length > 0 && {proxytype: proxyTypes},
            ...Array.isArray(versions) && versions.length > 0 && {version: versions},
            ...attrs && {attrs},
            index
        };
        props.onClickSave(updatedOverride);
    };

    return (
        <Grid className="masterContainer">
            <Grid.Column width={16}>
                {
                    props.loading ?
                        <Message color="yellow" icon>
                            <Icon name="spinner" loading/>
                            <Message.Content>Loading override settings...</Message.Content>
                        </Message> :
                        props.loadingErrorMessage ?
                            <Message color="red" icon>
                                <Icon name="exclamation"/>
                                <Message.Content>{props.loadingErrorMessage}</Message.Content>
                            </Message> : ""
                }
                <Form>
                    <Form.Group widths="equal">
                        <Form.Field>
                            {
                                !props.loading ?
                                    <Popup
                                        open={props.unsavedChanges && props.userCanEdit}
                                        content="You have made unsaved changes."
                                        trigger={
                                            <ContingentButton
                                                primary
                                                onClick={onClickSave}
                                                icon
                                                service={props.service}
                                                module={props.module}
                                                scope="all"
                                                user={props.user}
                                                allPermissions={props.permissions}
                                            ><Icon name="save"/>Save Override Settings</ContingentButton>
                                        }
                                        position="right center"
                                    /> : ""
                            }
                        </Form.Field>
                        <Form.Field>
                            {
                                props.selectedOverride.hasOwnProperty("ruleId") && !props.loading ?
                                    <ContingentButton
                                        floated="right"
                                        color="red"
                                        onClick={() => props.onClickDelete(index)}
                                        icon
                                        service={props.service}
                                        module={props.module}
                                        scope="all"
                                        user={props.user}
                                        allPermissions={props.permissions}
                                    >
                                        <Icon name="trash"/>&nbsp;Delete Override Setting
                                    </ContingentButton> : ""
                            }
                        </Form.Field>
                    </Form.Group>
                    <div>
                        {
                            props.saving ?
                                <Message color="yellow" icon>
                                    <Icon name="spinner" loading />
                                    <Message.Content>Saving override settings...</Message.Content>
                                </Message> :
                                props.saveSuccessMessage ?
                                    <Message icon color="green">
                                        <Icon name="check" />
                                        <Message.Content>{props.saveSuccessMessage}</Message.Content>
                                    </Message> :
                                    props.saveErrorMessage ?
                                        <Message icon color="red">
                                            <Icon name="exclamation" />
                                            <Message.Content>{props.saveErrorMessage}</Message.Content>
                                        </Message> : ""
                        }
                        {
                            Object.keys(props.selectedOverride).length < 1 && !props.newClicked ?
                                <Message icon color="yellow">
                                    <Icon name="info" />
                                    <Message.Content>
                                        Please select an existing override or create a new one to continue.
                                    </Message.Content>
                                </Message> :
                                <>
                                    <Form.Group widths="equal">
                                        <Form.Field>
                                            <Popup
                                                open={props.otherRuleIds.includes(ruleId)}
                                                trigger={
                                                    <Form.Field
                                                        label="Rule ID"
                                                        control={Input}
                                                        value={ruleId}
                                                        onChange={(event, {value}) => setRuleId(value)}
                                                        error={props.otherRuleIds.includes(ruleId)}
                                                    />
                                                }
                                                content="This name is already in use."
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                    <Form.Group widths="equal">
                                        <Form.Field
                                            label="Description"
                                            control={TextArea}
                                            value={description}
                                            onChange={(event, {value}) => setDescription(value)}
                                        />
                                    </Form.Group>
                                    <Segment basic style={{background: cidrs.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                                        <Divider horizontal content="CIDRs" />
                                        {
                                            props.userCanEdit ?
                                                <Form.Group widths="equal">
                                                    <Form.Field
                                                        control={Input}
                                                        label="Enter CIDR to Add"
                                                        value={cidrToAdd}
                                                        fluid
                                                        onChange={(event, {value}) => setCidrToAdd(value)}
                                                    />
                                                    <Form.Field>
                                                        <label>&nbsp;</label>
                                                        <Button fluid onClick={() => {
                                                            setCidrs(cidrs.slice().concat([cidrToAdd]));
                                                            setCidrToAdd("");
                                                        }}>Add CIDR</Button>
                                                    </Form.Field>
                                                </Form.Group> : ""
                                        }
                                        {
                                            cidrs.map((cidr, index) => {
                                                return <Label
                                                    as="a"
                                                    circular
                                                    key={`cidr-${cidr}`}
                                                    onClick={() => {
                                                        let updatedCidrs = cidrs.slice();
                                                        updatedCidrs.splice(index, 1);
                                                        setCidrs(updatedCidrs);
                                                    }}
                                                >
                                                    {cidr}
                                                    <Icon name="delete"/>
                                                </Label>
                                            })
                                        }
                                    </Segment>
                                    <Segment basic style={{background: networks.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                                        <Divider horizontal content="Networks" />
                                        {
                                            props.userCanEdit ?
                                                <Form.Group widths="equal">
                                                    <Form.Field
                                                        control={Select}
                                                        label="Select Networks To Add"
                                                        value={networksToAdd}
                                                        options={remainingNetworkOptions}
                                                        multiple
                                                        selection
                                                        search
                                                        fluid
                                                        onChange={(event, {value}) => {
                                                            console.log("settings networks to add: ", value);
                                                            setNetworksToAdd(value);
                                                        }}
                                                    />
                                                    <Form.Field>
                                                        <label>&nbsp;</label>
                                                        <Button
                                                            fluid
                                                            onClick={
                                                                () => {
                                                                    const updatedNetworks = networks.slice().concat(networksToAdd);
                                                                    setNetworks(updatedNetworks);
                                                                    setNetworksToAdd([]);
                                                                }
                                                            }>Add Selected Networks</Button>
                                                    </Form.Field>
                                                </Form.Group> : ""
                                        }
                                        {
                                            networks.map((network, index) => {
                                                return <Label
                                                    as="a"
                                                    circular
                                                    key={`network-${network}`}
                                                    onClick={() => {
                                                        let updatedNetworks = networks.slice();
                                                        updatedNetworks.splice(index, 1);
                                                        setNetworks(updatedNetworks);
                                                    }}
                                                >
                                                    {network}
                                                    <Icon name="delete"/>
                                                </Label>
                                            })
                                        }
                                    </Segment>
                                    <Segment basic style={{background: products.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                                        <Divider horizontal content="Products" />
                                        {
                                            props.userCanEdit ?
                                                <Form.Group widths="equal">
                                                    <Form.Field
                                                        control={Select}
                                                        label="Select Products to Add"
                                                        value={productsToAdd}
                                                        options={remainingProductOptions}
                                                        multiple
                                                        selection
                                                        search
                                                        fluid
                                                        onChange={(event, {value}) => setProductsToAdd(value)}
                                                    />
                                                    <Form.Field>
                                                        <label>&nbsp;</label>
                                                        <Button
                                                            fluid
                                                            onClick={
                                                                () => {
                                                                    const updatedProducts = products.slice().concat(productsToAdd);
                                                                    setProducts(updatedProducts);
                                                                    setProductsToAdd([]);
                                                                }
                                                            }
                                                        >Add Selected Products</Button>
                                                    </Form.Field>
                                                </Form.Group> : ""
                                        }
                                        {
                                            products.map((product, index) => {
                                                return <Label
                                                    as="a"
                                                    circular
                                                    key={`product-${product}`}
                                                    onClick={() => {
                                                        let updatedProducts = products.slice();
                                                        updatedProducts.splice(index, 1);
                                                        setProducts(updatedProducts);
                                                    }}
                                                >
                                                    {product}
                                                    <Icon name="delete"/>
                                                </Label>
                                            })
                                        }
                                    </Segment>
                                    <Segment basic style={{background: platforms.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                                        <Divider horizontal content="Platforms" />
                                        {
                                            props.userCanEdit ?
                                                <Form.Group widths="equal">
                                                    <Form.Field
                                                        control={Select}
                                                        label="Select Platforms to Add"
                                                        value={platformsToAdd}
                                                        options={remainingPlatformOptions}
                                                        multiple
                                                        selection
                                                        search
                                                        fluid
                                                        onChange={(event, {value}) => setPlatformsToAdd(value)}
                                                    />
                                                    <Form.Field>
                                                        <label>&nbsp;</label>
                                                        <Button
                                                            fluid
                                                            onClick={
                                                                () => {
                                                                    const updatedPlatforms = platforms.slice().concat(platformsToAdd);
                                                                    setPlatforms(updatedPlatforms);
                                                                    setPlatformsToAdd([]);
                                                                }
                                                            }
                                                        >Add Selected Platforms</Button>
                                                    </Form.Field>
                                                </Form.Group> : ""
                                        }
                                        {
                                            platforms.map((platform, index) => {
                                                return <Label
                                                    as="a"
                                                    circular
                                                    key={`platform-${platform}`}
                                                    onClick={() => {
                                                        let updatedPlatforms = platforms.slice();
                                                        updatedPlatforms.splice(index, 1);
                                                        setPlatforms(updatedPlatforms);
                                                    }}
                                                >
                                                    {platform}
                                                    <Icon name="delete"/>
                                                </Label>
                                            })
                                        }
                                    </Segment>

                                    <Segment basic style={{background: proxyIds.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                                        <Divider horizontal content="Proxy IDs" />
                                        {
                                            props.userCanEdit ?
                                                <Form.Group widths="equal">
                                                    <Form.Field
                                                        control={Input}
                                                        label="Enter Proxy ID to Add"
                                                        value={proxyIdToAdd}
                                                        fluid
                                                        onChange={(event, {value}) => setProxyIdToAdd(value)}
                                                    />
                                                    <Form.Field>
                                                        <label>&nbsp;</label>
                                                        <Button
                                                            fluid
                                                            onClick={
                                                                () => {
                                                                    setProxyIds(proxyIds.slice().concat([proxyIdToAdd]));
                                                                    setProxyIdToAdd("");
                                                                }
                                                            }
                                                        >Add Proxy ID</Button>
                                                    </Form.Field>
                                                </Form.Group> : ""
                                        }
                                        {
                                            proxyIds.map((proxyId, index) => {
                                                return <Label
                                                    as="a"
                                                    circular
                                                    key={`proxyid-${proxyId}`}
                                                    onClick={() => {
                                                        let updatedProxyIds = proxyIds.slice();
                                                        updatedProxyIds.splice(index, 1);
                                                        setProxyIds(updatedProxyIds);
                                                    }}
                                                >
                                                    {proxyId}
                                                    <Icon name="delete"/>
                                                </Label>
                                            })
                                        }
                                    </Segment>
                                    <Segment basic style={{background: proxyTypes.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                                        <Divider horizontal content="Proxy Types" />
                                        {
                                            props.userCanEdit ?
                                                <Form.Group widths="equal">
                                                    <Form.Field
                                                        control={Input}
                                                        label="Enter Proxy Type to Add"
                                                        value={proxyTypeToAdd}
                                                        fluid
                                                        onChange={(event, {value}) => setProxyTypeToAdd(value)}
                                                    />
                                                    <Form.Field>
                                                        <label>&nbsp;</label>
                                                        <Button
                                                            fluid
                                                            onClick={
                                                                () => {
                                                                    setProxyTypes(proxyTypes.slice().concat([proxyTypeToAdd]));
                                                                    setProxyTypeToAdd("");
                                                                }
                                                            }
                                                        >Add Proxy Type</Button>
                                                    </Form.Field>
                                                </Form.Group> : ""
                                        }
                                        {
                                            proxyTypes.map((proxyType, index) => {
                                                return <Label
                                                    as="a"
                                                    circular
                                                    key={`proxytype-${proxyType}`}
                                                    onClick={
                                                        () => {
                                                            let updatedProxyTypes = proxyTypes.slice();
                                                            updatedProxyTypes.splice(index, 1);
                                                            setProxyTypes(updatedProxyTypes);
                                                        }
                                                    }
                                                >{proxyType}<Icon name="delete"/>
                                                </Label>
                                            })
                                        }
                                    </Segment>
                                    <Segment basic style={{background: versions.length > 0 ? "lightblue" : "rgba(0, 0, 0, 0.00)"}}>
                                        <Divider horizontal content="Versions" />
                                        {
                                            props.userCanEdit ?
                                                <Form.Group widths="equal">
                                                    <Form.Field
                                                        control={Select}
                                                        label="Select Versions to Add"
                                                        value={versionsToAdd}
                                                        options={VERSION_OPTIONS}
                                                        multiple
                                                        selection
                                                        search
                                                        fluid
                                                        onChange={(event, {value}) => setVersionsToAdd(value)}
                                                    />
                                                    <Form.Field>
                                                        <label>&nbsp;</label>
                                                        <Button
                                                            fluid
                                                            onClick={
                                                                () => {
                                                                    const updatedVersions = versions.slice().concat(versionsToAdd);
                                                                    setVersions(updatedVersions);
                                                                    setVersionsToAdd([]);
                                                                }
                                                            }
                                                        >Add Selected Versions</Button>
                                                    </Form.Field>
                                                </Form.Group> : ""
                                        }
                                        {
                                            versions.map((version, index) => {
                                                return <Label
                                                    as="a"
                                                    circular
                                                    key={`version-${version}`}
                                                    onClick={() => {
                                                        let updatedVersions = versions.slice();
                                                        updatedVersions.splice(index, 1);
                                                        setVersions(updatedVersions);
                                                    }}
                                                >
                                                    {version}
                                                    <Icon name="delete"/>
                                                </Label>
                                            })
                                        }
                                    </Segment>
                                    <Divider horizontal content="Attributes" />
                                </>
                        }

                    </div>
                </Form>
                {
                    Object.keys(props.selectedOverride).length < 1 && !props.newClicked ? "" :
                        props.userCanEdit ? <JSONEditorImpl attrs={attrs} setAttrs={setAttrs} /> :
                            <Segment><pre>{JSON.stringify(attrs, null, 4)}</pre></Segment>
                }
            </Grid.Column>
        </Grid>
    );
};

class JSONEditorImpl extends Component {
    constructor(props) {
        super(props);

        this.state = {
            editorText: Object.assign({}, props.attrs)
        };
    }

    componentDidMount() {
        const options = {
            mode: 'text',
            onChangeText: jsonString => {
                if (jsonString !== JSON.stringify(this.state.editorText)) {
                    console.log("JSONEditorImpl.componentDidMount.onChangeText jsonString, attrs, and editortext? ", jsonString, this.props.attrs, this.state.editorText);
                    try {
                        this.setState({editorText: JSON.parse(jsonString)});
                        this.props.setAttrs(this.state.editorText);
                    } catch (e) {
                        console.error("JSONEditorImpl.componentDidMount.onChangeText) error with json.parse on ", jsonString);
                    }
                }
            }
        };
        this.jsoneditor = new JSONEditor(this.container, options);
        this.jsoneditor.set(this.state.editorText);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.attrs !== prevProps.attrs)  {
            console.log("JSONEditorImpl.componentDidUpdate attrs updated, attrs and editortext? ", this.props.attrs, this.state.editorText);
            if (this.props.attrs !== this.state.editorText) {
                this.setState({editorText: this.props.attrs});
            }
        }
        if (this.state.editorText !== prevState.editorText) {
            console.log("JSONEditorImpl.componentDidUpdate editorText updated, attrs and editortext? ", this.props.attrs, this.state.editorText);
            this.jsoneditor.set(this.state.editorText);
            this.props.setAttrs(this.state.editorText);
        }
    }

    render() {
        return <div style={{height: "57vh"}} ref={elem => this.container = elem}/>;
    }
}
