import React, {useState, useEffect} from "react";
import {Grid} from "semantic-ui-react";
import GeoOverridesSelector from "./GeoOverridesSelector";
import GeoDataProvider from "../../../Services/GeoDataProvider";
import GeoOverridesDetailView from "./GeoOverridesDetailView";

export default function GeoOverridesArrangementTab(props) {
    const [overrides, setOverrides] = useState([]);
    const [selectedKey, setSelectedKey] = useState("");
    const [otherRuleIds, setOtherRuleIds] = useState([]);
    const [selectedOverride, setSelectedOverride] = useState({});
    const [saving, setSaving] = useState(false);
    const [newClicked, setNewClicked] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);
    const [rulesSelected, setRulesSelected] = useState({});
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [isSelectedOverrideUpdated, setIsSelectedOverrideUpdated] = useState(false);
    const [settingsRearranged, setSettingsRearranged] = useState(false);
    const [loadingErrorMessage, setLoadingErrorMessage] = useState("");
    const [saveErrorMessage, setSaveErrorMessage] = useState("");
    const [saveSuccessMessage, setSaveSuccessMessage] = useState("");
    const [userCanEdit, setUserCanEdit] = useState(false);

    useEffect(function initializeOverrides() {
        setOverrides(props.overrides);
    }, [JSON.stringify(props.overrides)]);

    useEffect(function updateUserCanEdit() {
        let updatedUserCanEdit = props.user.authz.includes("urn:all:aspen-power");

        if (!updatedUserCanEdit) {
            if (props.user.hasOwnProperty("authz") && Array.isArray(props.user.authz)) {
                for (const permission of props.permissions.slice()) {
                    if (props.user.authz.includes(permission.urn) && permission.service === props.service && permission.module === props.module && ["editor", "aspen-power"].includes(permission.role)) {
                        updatedUserCanEdit = true;
                        break;
                    }
                }
            }
        }

        setUserCanEdit(updatedUserCanEdit);
    }, [JSON.stringify(props.user), JSON.stringify(props.permissions)]);

    useEffect(function onNewClicked() {
        if (newClicked) {
            setSelectedKey("");
            setSelectedOverride({
                index: overrides.length
            });
        }
    }, [newClicked]);

    useEffect(function checkOverridesRearrangement() {
        for (let i = 0; i < overrides.length; i++) {
            if (overrides[i].index !== i) {
                setSettingsRearranged(true);
                break;
            }
        }
    }, [JSON.stringify(overrides)]);

    useEffect(function updateUnsavedChanges() {
        setUnsavedChanges(settingsRearranged || isSelectedOverrideUpdated);
    }, [settingsRearranged, isSelectedOverrideUpdated]);

    useEffect(function onSelectedOverrideUpdate() {
        const updatedRuleIds = [];
        for (const override of overrides) {
            if (override.index !== selectedOverride.index) {
                updatedRuleIds.push(override.ruleId);
            }
        }
        setOtherRuleIds(updatedRuleIds);
        setSaveErrorMessage("");
        setSaveSuccessMessage("");
    }, [JSON.stringify(selectedOverride)]);

    useEffect(function onClickExport() {
        if (openExportModal) {

        }
    }, [openExportModal]);


    const onClickSave = updatedOverride => {
        console.log("GeoOverridesMasterDetailView.onClickSave updatedOverride: ", updatedOverride);
        setSaveSuccessMessage("");
        setSaveErrorMessage("");
        setLoadingErrorMessage("");
        console.log("GeoOverridesMasterDetailView.onClickSave: saving override: ", updatedOverride);

        const updatedOverrides = overrides.slice();

        if (updatedOverride.ruleId) {
            if (!updatedOverride.ruleId || !updatedOverride.description) {
                setSaveErrorMessage("Please give this override rule an ID and a description.");
                return;
            }

            if (updatedOverride.index < 0) {
                setSaveErrorMessage("This rule had an invalid index.");
                return;
            }

            if (!newClicked) {
                for (let i = 0; i < updatedOverrides.length; i++) {
                    if (updatedOverrides[i].index === updatedOverride.index) {
                        updatedOverrides[i] = Object.assign({}, updatedOverride);
                        break;
                    }
                }
            } else {
                updatedOverrides.push(Object.assign({}, updatedOverride));
            }
        } else {
            console.log("GeoOverridesMasterDetailView.onClickSave: override did not have a ruleId", updatedOverride);
        }

        for (const override of updatedOverrides) {
            delete override.index;
        }

        console.log("GeoOverridesMasterDetailView.onClickSave: overrides that will be saved: ", updatedOverrides);

        setSaving(true);
        GeoDataProvider.setMetadata("overrides", updatedOverrides).then(response => {
            console.log("GeoOverridesMasterDetailView.onClickSave response: ", response);
            props.refreshOverrides();
        }).then(() => {
            setSaveSuccessMessage("This override setting has been successfully saved.");
            setNewClicked(false);
            if (updatedOverride.ruleId) {
                for (const override of overrides) {
                    if (override.index === updatedOverride.index) {
                        setSelectedOverride(override);
                        break;
                    }
                }
            }

            setSettingsRearranged(false);
            setIsSelectedOverrideUpdated(false);
            setTimeout(() => {
                setSaveSuccessMessage("");
            }, 5000);
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage("There has been an error saving the override setting.");
        }).finally(() => {
            setSaving(false);
        });
    };

    const onClickDelete = index => {
        if (index < 0) {
            return;
        }

        const updatedOverrides = [];
        for (const override of overrides) {
            if (override.index !== index) {
                updatedOverrides.push(Object.assign(override));
            }
        }

        for (const override of updatedOverrides) {
            delete override.index;
        }

        console.log("GeoOverridesMasterDetailView.onClickDelete overrides: ", updatedOverrides);

        setSaving(true);
        GeoDataProvider.setMetadata("overrides", updatedOverrides).then(response => {
            console.log("GeoOverridesMasterDetailView.onClickSave response: ", response);
            props.refreshOverrides();
            setSaveSuccessMessage("This override setting has been successfully deleted.");
            setTimeout(() => {
                setSaveSuccessMessage("");
            }, 5000);
        }).catch(error => {
            console.error(error);
            setSaveErrorMessage("There has been an error deleting the override setting.");
        }).finally(() => {
            setSaving(false);
        });
    };

    return (
        <Grid className="masterContainer">
            <Grid.Column width={4}>
                <GeoOverridesSelector
                    GeoDataProvider={GeoDataProvider}
                    loading={props.loading}
                    overrides={overrides}
                    setOverrides={setOverrides}
                    selectedKey={selectedKey}
                    setSelectedKey={setSelectedKey}
                    newClicked={newClicked}
                    setNewClicked={setNewClicked}
                    refreshOverrides={props.refreshOverrides}
                    selectedOverride={selectedOverride}
                    setSelectedOverride={setSelectedOverride}
                    rulesSelected={rulesSelected}
                    setRulesSelected={setRulesSelected}
                    openExportModal={openExportModal}
                    setOpenExportModal={setOpenExportModal}
                    service={props.service}
                    permissions={props.permissions}
                    module={props.module}
                    user={props.user}
                    loadingPermissions={props.loadingPermissions}
                    userCanEdit={userCanEdit}
                />
            </Grid.Column>
            <Grid.Column width={12}>
                <GeoOverridesDetailView
                    otherRuleIds={otherRuleIds}
                    unsavedChanges={unsavedChanges}
                    isSelectedOverrideUpdated={isSelectedOverrideUpdated}
                    setIsSelectedOverrideUpdated={setIsSelectedOverrideUpdated}
                    selectedOverride={selectedOverride}
                    onClickDelete={onClickDelete}
                    loadingErrorMessage={loadingErrorMessage}
                    setLoadingErrorMessage={setLoadingErrorMessage}
                    saveErrorMessage={saveErrorMessage}
                    saveSuccessMessage={saveSuccessMessage}
                    newClicked={newClicked}
                    onClickSave={onClickSave}
                    saving={saving}
                    service={props.service}
                    permissions={props.permissions}
                    module={props.module}
                    user={props.user}
                    loadingPermissions={props.loadingPermissions}
                    userCanEdit={userCanEdit}
                />
            </Grid.Column>
        </Grid>
    );
};
